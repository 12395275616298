.nav {
    background-color: white;
    z-index:          100;
    position:         absolute;
    top:              60px;
    bottom:           0;
    max-width:        0;
    right:            0;
    width:            0;
    box-sizing:       border-box;
    overflow:         hidden;
    transition:       max-width 300ms ease;
}

.nav.open {
    max-width: 100%;
    width:     100%;
}

@media (min-width: 1024px) {
    .nav {
        max-width:       none;
        width:           100%;
        padding:         0;
        position:        unset;
        background:      transparent;
        display:         flex;
        align-items:     center;
        justify-content: flex-end;
        margin-right:    24px;
    }
}

.navHeader {
    height:          var(--header-height);
    box-sizing:      border-box;
    display:         flex;
    align-items:     center;
    justify-content: center;
}

@media (min-width: 1024px) {
    .navHeader {
        display: none;
    }
}

.list {
    list-style:  none;
    align-items: center;
    margin:      16px 24px;
}

@media (min-width: 1024px) {
    .list {
        display: flex;
        margin:  0;
    }
}

.item {
    margin:  0;
    padding: 0;
    display: block;
}

.link {
    padding:         8px 16px;
    cursor:          pointer;
    opacity:         1;
    font-weight:     500;
    display:         flex;
    align-items:     center;
    justify-content: space-between;
}

.link:hover {
    opacity: 1;
}

.label {
    font-size:     22px;
    font-weight:   600;
    transition:    border 100ms ease;
    border-bottom: 1px solid transparent;
}

@media (min-width: 1024px) {
    .label {
        font-size: unset;
    }
}

.link:hover .label {
    opacity: 1;
    color:   theme('colors.brand-purple');
}

.icon {
    font-size:  16px !important;
    transition: all 200ms ease;
}

@media (min-width: 1024px) {
    .icon {
        margin-left: 6px;
        font-size:   12px !important;
    }
}

.iconActive {
    transform: rotate(90deg);
}

.dropdown {
    background-color: white;
    padding:          0;
    position:         fixed;
    width:            100%;
    top:              0;
}

@media (min-width: 1024px) {
    .dropdown {
        padding: unset;
        width:   100%;
        z-index: 100;
    }
}