.tags {
	margin-top: 8px;
}

.tags:not(:empty) {
	margin-bottom: -4px;
}

.tag {
	background-color: var(--g-color-n20);
	border:           1px solid var(--g-color-border);
	padding:          2px 8px;
	border-radius:    500px;
	margin-bottom:    4px;
	margin-right:     4px;
	display:          inline-block;
}

.tagText {
	display:        inline-block;
	vertical-align: middle;
}

.tagClose {
	vertical-align: middle;
	display:        inline-block;
	margin-left:    4px;
	cursor:         pointer;
	opacity:        0.3;
	transition:     opacity 200ms ease;
}

.tagClose:hover {
	opacity: 0.8;
}

.tagCloseIcon {
	display:        block;
	vertical-align: middle;
	line-height:    16px;
	font-size:      16px;
}