input,
textarea,
select,
button,
:root {
    font-family:             -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                             'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                             sans-serif;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline:                 none !important;
}

body {
    margin:  0;
    padding: 0;
}

:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

body {
    margin:     0;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    display:    block;
    margin-top: 0;
}

p {
    margin: 0;
}

ul, ol {
    list-style: none;
    padding:    0;
    margin:     0;
}

li {
    padding: 0;
}

button {
    font: inherit;
}

input {
    font-size: 1rem;
}

a {
    text-decoration: none;
    outline:         none;
}


button:focus, input:focus, a:focus, textarea:focus,
button, input, a, textarea {
    outline: none;
}

:root {
    --g-color-n0:                     #FFFFFF;
    --g-color-n10:                    #FAFBFC;
    --g-color-n20:                    #F5F5F7;
    --g-color-n30:                    #EBECF0;
    --g-color-n40:                    #DFE1E6;
    --g-color-n50:                    #C1C7D0;

    --g-color-n60:                    #B3BAC5;
    --g-color-n70:                    #A5ADBA;
    --g-color-n80:                    #97A0AF;
    --g-color-n90:                    #8993A4;
    --g-color-n100:                   #7A869A;

    --g-color-n200:                   #6B778C;
    --g-color-n300:                   #5E6C84;
    --g-color-n400:                   #505F79;

    --g-color-n500:                   #42526E;
    --g-color-n600:                   #344563;
    --g-color-n700:                   #2B3950;
    --g-color-n800:                   #222D38;
    --g-color-n900:                   #12191F;

    --g-color-a100:                   rgba(52, 69, 99, 0.04);
    --g-color-a200:                   rgba(52, 69, 99, 0.08);
    --g-color-a300:                   rgba(52, 69, 99, 0.15);
    --g-color-a400:                   rgba(52, 69, 99, 0.2);
    --g-color-a500:                   rgba(52, 69, 99, 0.27);

    --g-color-red100:                 #ffe4e9;
    --g-color-red200:                 #fcc3cb;
    --g-color-red300:                 #f58c9e;
    --g-color-red400:                 #ee4c6d;
    --g-color-red500:                 #e12449;
    --g-color-red600:                 #c91235;
    --g-color-red700:                 #ad0b2a;
    --g-color-red800:                 #80061d;
    --g-color-red900:                 #4f0110;
    --g-color-red1000:                #3b010b;

    --g-color-yel100:                 #FFF7D6;
    --g-color-yel200:                 #F8E6A0;
    --g-color-yel300:                 #F4CC46;
    --g-color-yel400:                 #E2B203;
    --g-color-yel500:                 #CF9F02;
    --g-color-yel600:                 #B38600;
    --g-color-yel700:                 #946F00;
    --g-color-yel800:                 #7F5F01;
    --g-color-yel900:                 #533F04;
    --g-color-yel1000:                #3D2E00;

    --g-color-gre100:                 #DFFCF0;
    --g-color-gre200:                 #BAF3DB;
    --g-color-gre300:                 #7EE2B8;
    --g-color-gre400:                 #4BCE97;
    --g-color-gre500:                 #2ABB7F;
    --g-color-gre600:                 #22A06B;
    --g-color-gre700:                 #1F845A;
    --g-color-gre800:                 #216E4E;
    --g-color-gre900:                 #164B35;
    --g-color-gre1000:                #133527;

    --g-color-blu100:                 #E9F2FF;
    --g-color-blu200:                 #CCE0FF;
    --g-color-blu300:                 #84B8FF;
    --g-color-blu400:                 #579DFF;
    --g-color-blu500:                 #388BFF;
    --g-color-blu600:                 #1D7AFC;
    --g-color-blu700:                 #0C66E4;
    --g-color-blu800:                 #0055CC;
    --g-color-blu900:                 #08316B;
    --g-color-blu1000:                #082145;

    --g-color-pur100:                 #F3F0FF;
    --g-color-pur200:                 #DFD8FD;
    --g-color-pur300:                 #B8ACF6;
    --g-color-pur400:                 #9F8FEF;
    --g-color-pur500:                 #8F7EE7;
    --g-color-pur600:                 #8270DB;
    --g-color-pur700:                 #6E5DC6;
    --g-color-pur800:                 #5E4DB2;
    --g-color-pur900:                 #352C63;
    --g-color-pur1000:                #231C3F;

    /* Box shadow for floating components */
    --g-shadow:                       0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    /* Spacing */
    --g-space:                        8px;
    --g-size-0:                       0;
    --g-size-0-5:                     4px;
    --g-size-1:                       8px;
    --g-size-1-5:                     12px;
    --g-size-2:                       16px;
    --g-size-3:                       24px;
    --g-size-4:                       32px;
    --g-size-6:                       48px;
    --g-size-8:                       64px;
    --g-size-12:                      96px;
    --g-size-16:                      128px;

    /* Tokens */
    --g-color-brand-purple: #6c5fc7;
    --g-color-brand-sand: #f5f2f2;

    --g-color-primary:                #59365e;
    --g-color-secondary:              #6c5fc7;
    --g-color-tertiary:               #f5f2f2;

    /* Text Colors */
    --g-color-text:                   var(--g-color-n900);
    --g-color-text-subtle:            var(--g-color-n100);
    --g-color-text-link:              var(--g-color-blu600);

    /* Contextual Colors*/
    --g-color-neutral:                var(--g-color-n40);
    --g-color-neutral-inverse:        var(--g-color-n700);
    --g-color-neutral-subtle:         var(--g-color-n20);
    --g-color-neutral-subtle-inverse: var(--g-color-n300);

    --g-color-success:                var(--g-color-gre500);
    --g-color-success-inverse:        var(--g-color-n0);
    --g-color-success-subtle:         var(--g-color-gre100);
    --g-color-success-subtle-inverse: var(--g-color-gre600);

    --g-color-warning:                var(--g-color-yel400);
    --g-color-warning-inverse:        var(--g-color-n900);
    --g-color-warning-subtle:         var(--g-color-yel100);
    --g-color-warning-subtle-inverse: var(--g-color-yel600);

    --g-color-info:                   var(--g-color-blu500);
    --g-color-info-inverse:           var(--g-color-n0);
    --g-color-info-subtle:            var(--g-color-blu100);
    --g-color-info-subtle-inverse:    var(--g-color-blu600);

    --g-color-danger:                 var(--g-color-red500);
    --g-color-danger-inverse:         var(--g-color-n0);
    --g-color-danger-subtle:          var(--g-color-red100);
    --g-color-danger-subtle-inverse:  var(--g-color-red600);

    --g-color-border:                 var(--g-color-n30);

    /* How extreme rounded elements are */
    --g-rounding:                     6px;
}