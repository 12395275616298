.switch {
    position: relative;
    display:  inline-block;
    width:    46px;
    height:   25px;
}

.input {
    opacity: 0;
    width:   0;
    height:  0;
}

.slider {
    position:           absolute;
    cursor:             pointer;
    top:                0;
    left:               0;
    right:              0;
    bottom:             0;
    background-color:   #CCCCCC;
    -webkit-transition: .4s;
    transition:         .4s;
    border-radius:      34px;
}

.slider:before {
    position:           absolute;
    content:            "";
    height:             18px;
    width:              18px;
    left:               4px;
    bottom:             4px;
    background-color:   white;
    -webkit-transition: .4s;
    transition:         .4s;
    border-radius:      50%;
}

.input:checked + .slider {
    background-color: var(--g-color-success);
}

.input:focus + .slider {
    box-shadow: 0 0 1px var(--g-color-success);
}

.input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform:     translateX(20px);
    transform:         translateX(20px);
}