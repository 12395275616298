.formDisabled {
    pointer-events: none;
    opacity:        0.5;
}

.field {
    margin-bottom: 20px;
}

.field:last-child {
    margin-bottom: 0;
}

.fieldError {
    margin-top:  8px;
    margin-left: 1px;
    color:       #d81343;
    display:     block;
    font-size:   0.94rem;
    line-height: 1.14rem;
}

.fieldInfo {
    margin-bottom: 12px;
    display:       flex;
    flex-wrap:     wrap;
    align-items:   center;
}

.fieldInfo:empty {
    display: none;
}

.fieldLabel {
    display:        inline-block;
    vertical-align: middle;
    font-weight:    600;
    color:          #2E3137;
}

.fieldDescription {
    margin-top:  8px;
    margin-left: 1px;
    font-weight: 300;
    display:     block;
    font-size:   0.94rem;
    line-height: 1.14rem;
    color:       #737A89;
}

.error {
    display:       block;
    margin-bottom: 16px;
    color:         #FF2D54;
}