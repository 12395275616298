.menu {
}

.item {
    border-bottom:  2px solid transparent;
    padding-bottom: 8px;
    margin:         0 16px -1px;
    display:        inline-block;
    font-weight:    500;
    font-size:      15px;
    cursor:         pointer;
}

.item:first-child {
    margin-left: 0;
}

.itemActive {
    color:        var(--g-color-secondary);
    border-color: var(--g-color-secondary);
}