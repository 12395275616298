.listbox {
    border-radius:    var(--g-rounding);
    background-color: var(--g-color-n20);
    border:           1px solid var(--g-color-border);
}

.search {
    border-top-left-radius:  var(--g-rounding);
    border-top-right-radius: var(--g-rounding);
    display:                 flex;
    align-items:             center;
    background-color:        white;
    overflow:                hidden;
    border-bottom:           1px solid var(--g-color-border);
}

.searchClear {
    cursor:       pointer;
    color:        var(--g-color-n50);
    transition:   color 200ms ease;
    margin-right: 16px;
}

.searchClear:hover {
    color: var(--g-color-n100);
}

.searchInput {
    border:      none;
    width:       100%;
    box-sizing:  border-box;
    height:      34px;
    line-height: 34px;
    color:       #4A5568;
    padding:     8px 12px;
}

.search:focus {
    outline: none !important;
}

.items {
    height:     200px;
    overflow-y: auto;
    max-height: 200px;
}

.item {
    background-color: var(--g-color-n0);
    border-bottom:    1px solid var(--g-color-n30);
    padding:          8px;
    display:          flex;
    justify-content:  space-between;
    vertical-align:   middle;
    cursor:           pointer;
    line-height:      18px;
}

.item:hover {
    background-color: var(--g-color-n10);
}

.select {
    cursor:     pointer;
    opacity:    0.3;
    transition: opacity 200ms ease;
}

.selectedIcon {
    color:          var(--g-color-secondary);
    display:        block;
    vertical-align: middle;
    line-height:    18px;
    font-size:      18px;
}

.empty {
    display:         flex;
    justify-content: center;
    align-items:     center;
    height:          200px;
}

.emptyText {
    color: var(--g-color-n300);
}