.wrapper {
	width:            100%;
	height:           200px;
	background-color: var(--g-color-n20);
	border-radius:    4px;
	text-align:       center;
	overflow:         hidden;
	border:           2px solid var(--g-color-border);
	cursor:           pointer;
	transition:       border 300ms ease;
	position:         relative;
}

.wrapper:hover {
	border-color: var(--g-color-secondary);
}

.image {
	max-width: 100%;
	height:    100%;
	display:   block;
	margin:    0 auto;
}

.placeholder {
	opacity:    0.5;
	margin-top: 60px;
}

.placeholderIcon {
	display:   block;
	font-size: 60px;
	margin:    0 auto 8px;
}

.placeholderText {
	display: block;
	margin:  0 auto;
}

.input {
	opacity:  0.0;
	position: absolute;
	top:      0;
	left:     0;
	bottom:   0;
	right:    0;
	width:    100%;
	height:   100%;
	cursor:   pointer;
}