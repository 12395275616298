.control {
	width:            100%;
	background-color: #FFFFFF;
	border-radius:    var(--g-rounding);
	box-sizing:       border-box;
	color:            #4A5568;
	border:           1px solid var(--g-color-border);
	transition:       border-color 200ms ease, background-color 200ms ease, box-shadow 200ms ease;
	height:           100%;
}

.selected {
	border-color: var(--g-color-secondary);
	box-shadow:   0 0 0 1px var(--g-color-secondary);
}

.control--rounded {
	border-radius: 500px;
}

.focusable:focus-within {
	box-shadow:   0 0 0 1px var(--g-color-secondary);
	border-color: var(--g-color-secondary);
}

.hoverable {
	cursor: pointer;
}

.hoverable:hover:not(:focus-within) {
	border-color: var(--g-color-secondary);
}

.clickable {
	cursor: pointer;
}

.disabled {
	background-color: #EDF2F7 !important;
	cursor:           default;
	opacity:          0.6;
}