.reveal {
}

.hidden {
    opacity:    0;
    transition: opacity 200ms ease;
}

.reveal:hover .hidden {
    opacity: 1;
}