@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin:                  0;
    font-family:             -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
                             'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                             sans-serif;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:        #f5f2f2;
}

a {
    color: initial;
}

h1, h2, h3, h4, h5, h6, p, span, a {
    color: #1b184d
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
                 monospace;
}

:root {
    font-size:       16px !important;
    --header-height: 74px
}

.bg-g-glass {
    background:              rgba(255, 255, 255, 0.05);
    border-radius:           16px;
    backdrop-filter:         blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border:                  1px solid rgba(255, 255, 255, 0.03);
}

/* Limit the width of a container to the regular width of the site */
.site-width {
    max-width:     1300px;
    margin:        0 auto;
    padding-right: 24px;
    padding-left:  24px;
    box-sizing:    border-box;
}

