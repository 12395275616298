.header {
    z-index: 100;
    padding: 16px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.inner {
    justify-content: space-between;
    display:         flex;
    align-items:     center;
}

.logo {
    flex-shrink: 0;
    height:      24px;
    width:       auto;
}

@media (min-width: 1024px) {
    .logo {
        height: 32px;
    }
}