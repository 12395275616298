.disabled .valueText {
    cursor: default !important;
}

.value {
    cursor:      pointer;
    box-sizing:  border-box;
    white-space: nowrap;
}

.valueText {
    cursor:         pointer;
    caret-color:    transparent;
    color:          inherit;
    outline:        none;
    border:         none;
    line-height:    34px;
    background:     transparent;
    padding:        8px 12px;
    vertical-align: middle;
    display:        inline-block;
    border-radius:  var(--g-rounding);
    width:          calc(100% - 24px);
    height:         34px;
    box-sizing:     border-box;
}

.valueIcons {
    vertical-align: middle;
    width:          16px;
    text-align:     right;
    display:        inline-block;
    padding-right:  8px;
}

.valueIcon {
    color:       #A5AEBD;
    font-size:   10px;
    width:       24px;
    line-height: 4px;
    display:     block;
}

.valueIcon:first-child {
    margin-bottom: -3px;
}

.valueIcon:last-child {
    margin-top: -3px;
}

.dropdown {
    display: none;
}

.inner {
    background-color: #ffffff;
    border-radius:    var(--g-rounding);
    box-shadow:       var(--g-shadow);
    overflow-x:       auto;
}

.open {
    display: block;
}

.options {
    max-height: 300px;
    overflow-y: auto;
}

.option {
    display: block;
    cursor:  pointer;
    padding: 8px 8px;
}

.optionLabel {
    display: inline-block;
}

.optionIcon {
    margin-right: 8px;
}

.option:hover {
    background-color: var(--g-color-a100);
}

.option svg:nth-child(1) {
    display: inline-block;
}

.option svg:nth-child(2) {
    display: none;
}

.optionActive svg:nth-child(1) {
    display: none;
}

.optionActive svg:nth-child(2) {
    display: inline-block;
}

.empty {
    padding:    24px 0;
    text-align: center;
}

.empty {
    display:    block;
    padding:    24px 0;
    text-align: center;
    color:      var(--g-color-n100);
}