/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/button/button.module.css ***!
  \****************************************************************************************************************/
.glass-button-module-button {
    cursor:        pointer;
    display:       inline-block;
    line-height:   1em;
    border:        1px solid transparent;
    transition:    opacity 200ms ease, background-color 200ms ease, border 200ms ease;
    border-radius: var(--g-rounding);
}

.glass-button-module-buttonWithIcon .glass-button-module-children {
    margin-left: 8px;
}

.glass-button-module-children {
    display:     inline-block;
    font-weight: 600;
    color:       inherit;
}

.glass-button-module-button + .glass-button-module-button {
    margin-left: 10px;
}

.glass-button-module-varPrimary {
    color:            var(--g-color-n0);
    border-color:     var(--g-color-secondary);
    background-color: var(--g-color-secondary);
}

.glass-button-module-varPrimary:hover {
    background-color: #594daf;
    border-color:     #594daf;
}

.glass-button-module-varDefault {
    color:            var(--g-color-n700);
    background-color: var(--g-color-n0);
    border-color:     var(--g-color-n30);
}

.glass-button-module-varDefault:hover {
    background-color: var(--g-color-n10);
}

.glass-button-module-varDestructive {
    color:            #FFFFFF;
    background-color: var(--g-color-danger);
    border-color:     var(--g-color-danger);
}

.glass-button-module-varDestructive:hover {
    border-color:     var(--g-color-red600);
    background-color: var(--g-color-red600);
}

.glass-button-module-varLink {
    color:            var(--g-color-secondary);
    background-color: transparent !important;
    padding:          0 !important;
}

.glass-button-module-varLink:hover {
    background-color: transparent;
}

.glass-button-module-varSubtle {
    color:            var(--g-color-n300);
    background-color: transparent;
    border-color:     transparent;
}

.glass-button-module-varSubtle:hover {
    background-color: white;
    border-color:     var(--g-color-n30);
}

.glass-button-module-varSubtle + .glass-button-module-varSubtle {
    margin-left: 0 !important;
}

.glass-button-module-icon {
    display:    inline-block;
    height:     1rem;
    max-height: 1rem;
    font-size:  1rem;
}

.glass-button-module-icon + .glass-button-module-loadingIcon {
    margin-left: 8px;
}

.glass-button-module-loadingIcon {
    max-height:  1rem;
    height:      1rem;
    line-height: 1rem;
    display:     none;
}

.glass-button-module-loading,
.glass-button-module-disabled {
    opacity: 0.4 !important;
    cursor:  default;
}

.glass-button-module-loading .glass-button-module-loadingIcon {
    display: inline-block;
}

.glass-button-module-fullwidth {
    display:    block;
    text-align: center;
}

.glass-button-module-fullwidth + .glass-button-module-fullwidth {
    margin-left: 0;
    margin-top:  8px;
}

.glass-button-module-spacingCompact {
    padding: 4px 6px;
}

.glass-button-module-spacingCompact .glass-button-module-children {
    vertical-align: text-bottom;
}

.glass-button-module-spacingCompact + .glass-button-module-spacingCompact {
    margin-left: 4px;
}

.glass-button-module-spacingStandard {
    padding: 8px 16px
}

.glass-button-module-spacingLarge {
    padding: 12px 18px;
}
/*!**************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/image/image.module.css ***!
  \**************************************************************************************************************/
.glass-image-module-container {
    display:    inline-block;
    transition: border 200ms ease;
    overflow:   hidden;
    position:   relative;
}

.glass-image-module-containerClickable {
    cursor: pointer;
}

.glass-image-module-containerClickable:hover {
    border-color: var(--g-color-secondary);
}

.glass-image-module-containerClickable:hover .glass-image-module-placeholder {
    color: var(--g-color-secondary);
}

.glass-image-module-placeholder {
    position:   absolute;
    top:        50%;
    left:       50%;
    transform:  translate(-50%, -50%);
    display:    block;
    opacity:    0.7;
    color:      var(--g-color-border);
    font-size:  24px;
    transition: color 200ms ease;
}

.glass-image-module-image {
    display:    block;
    height:     100%;
    width:      100%;
    border:     none;
    object-fit: cover;
}
/*!**************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/table/table.module.css ***!
  \**************************************************************************************************************/
.glass-table-module-table {
    table-layout:     auto;
    width:            100%;
    border-collapse:  separate;
    border:           1px solid var(--g-color-border);
    border-spacing:   0;
    border-bottom:    none;
    border-radius:    var(--g-rounding);
    position:         relative;
    z-index:          0;
    overflow:         hidden;
    background-color: var(--g-color-n0);
}

.glass-table-module-embedded {
    border:        none;
    border-radius: 0;
}

.glass-table-module-embedded thead {
    display: none;
}

.glass-table-module-header {
    position:         sticky;
    background-color: var(--g-color-n0);
    top:              0;
    z-index:          1;
}

.glass-table-module-headerCell {
    text-align:    left;
    padding:       var(--g-size-1);
    font-weight:   500;
    border-bottom: 1px solid var(--g-color-border);
}

.glass-table-module-bodyCell {
    padding:       var(--g-size-1);
    overflow:      hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid var(--g-color-border);
}

.glass-table-module-bodyCellShrink {
    width:       0.1%;
    white-space: nowrap;
}

.glass-table-module-row {
    transition: background-color 200ms ease;
}

.glass-table-module-row:not(:last-child) {
    border-bottom: 1px solid Var(--g-color-border);
}

.glass-table-module-rowClickable:hover {
    background-color: var(--g-color-n10);
    cursor:           pointer;
}

.glass-table-module-empty {
    padding:    32px 0 24px 0;
    text-align: center;
}

.glass-table-module-emptyIcon {
    display:   inline-block;
    font-size: 32px;
    color:     var(--g-color-border);
    margin:    0 auto 16px auto;
}

.glass-table-module-emptyMessage {
    display: block;
    color:   var(--g-color-border);
}
/*!********************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/backdrop/backdrop.module.css ***!
  \********************************************************************************************************************/
.glass-backdrop-module-backdrop {
    z-index:          1;
    top:              0;
    right:            0;
    bottom:           0;
    left:             0;
    position:         fixed;
    background-color: rgba(56, 56, 56, 0.55);
    transition:       opacity 300ms ease;
}
/*!**************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/modal/modal.module.css ***!
  \**************************************************************************************************************/
.glass-modal-module-wrapper {
    z-index:  1;
    top:      0;
    right:    0;
    bottom:   0;
    left:     0;
    position: fixed;
}

.glass-modal-module-modal {
    background:     white;
    box-shadow:     0 3px 6px 0 rgba(0, 0, 0, 0.12), 0px 7px 14px 0px rgba(60, 66, 87, 0.08);
    max-width:      540px;
    margin:         10vh auto 0;
    border-radius:  var(--g-rounding);
    z-index:        1;
    padding:        28px;
    display:        flex;
    flex-direction: column;
}

.glass-modal-module-sizeSm {
    max-width: 540px;
}

.glass-modal-module-sizeMd {
    max-width: 700px;
}

.glass-modal-module-sizeLg {
    max-width: 1100px;
}

.glass-modal-module-sizeXl {
    max-width:  90vw;
    max-height: 90vh !important;
    height:     90vh;
    margin-top: 5vh;
}

.glass-modal-module-header {
}

.glass-modal-module-headerTop {
    display:         flex;
    justify-content: space-between;
    margin-bottom:   22px;
}

.glass-modal-module-headerBottom {
    margin-top:    -16px;
    margin-bottom: 26px;
}

.glass-modal-module-headerTitle {
    font-size:   20px;
    line-height: 1;
    font-weight: 700;
    display:     block;
    align-items: center;
}

.glass-modal-module-headerRight {
    display:     flex;
    align-items: center;
    gap:         8px;
    margin-top:  -8px;
}

.glass-modal-module-headerClose {
    display: block;
}

.glass-modal-module-headerCloseIcon {
    font-size:     22px;
    line-height:   22px;
    cursor:        pointer;
    text-align:    center;
    color:         var(--g-color-n100);
    transition:    opacity 300ms ease;
    border-radius: var(--g-rounding);
    width:         22px;
    height:        22px;
    padding:       4px;
}

.glass-modal-module-headerCloseIcon:hover {
    background-color: var(--g-color-n20);
    cursor:           pointer;
}

.glass-modal-module-option {
    display: block;
}

.glass-modal-module-optionIcon {
    font-size:     22px;
    line-height:   22px;
    cursor:        pointer;
    text-align:    center;
    color:         var(--g-color-n100);
    transition:    opacity 300ms ease;
    border-radius: var(--g-rounding);
    width:         22px;
    height:        22px;
    padding:       4px;
}

.glass-modal-module-optionIcon:hover {
    background-color: var(--g-color-n20);
    cursor:           pointer;
}

.glass-modal-module-body {
    overflow-y: auto;
    flex-grow:  1;

    /* Pad body to ensure input hover states aren't cut off */
    padding:    1px;
}

.glass-modal-module-footer {
    margin-top:                 32px;
    border-bottom-left-radius:  var(--g-rounding);
    border-bottom-right-radius: var(--g-rounding);
}
/*!************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/form/form.module.css ***!
  \************************************************************************************************************/
.glass-form-module-formDisabled {
    pointer-events: none;
    opacity:        0.5;
}

.glass-form-module-field {
    margin-bottom: 20px;
}

.glass-form-module-field:last-child {
    margin-bottom: 0;
}

.glass-form-module-fieldError {
    margin-top:  8px;
    margin-left: 1px;
    color:       #d81343;
    display:     block;
    font-size:   0.94rem;
    line-height: 1.14rem;
}

.glass-form-module-fieldInfo {
    margin-bottom: 12px;
    display:       flex;
    flex-wrap:     wrap;
    align-items:   center;
}

.glass-form-module-fieldInfo:empty {
    display: none;
}

.glass-form-module-fieldLabel {
    display:        inline-block;
    vertical-align: middle;
    font-weight:    600;
    color:          #2E3137;
}

.glass-form-module-fieldDescription {
    margin-top:  8px;
    margin-left: 1px;
    font-weight: 300;
    display:     block;
    font-size:   0.94rem;
    line-height: 1.14rem;
    color:       #737A89;
}

.glass-form-module-error {
    display:       block;
    margin-bottom: 16px;
    color:         #FF2D54;
}
/*!********************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/control-design/control-design.module.css ***!
  \********************************************************************************************************************************/
.glass-control-design-module-control {
	width:            100%;
	background-color: #FFFFFF;
	border-radius:    var(--g-rounding);
	box-sizing:       border-box;
	color:            #4A5568;
	border:           1px solid var(--g-color-border);
	transition:       border-color 200ms ease, background-color 200ms ease, box-shadow 200ms ease;
	height:           100%;
}

.glass-control-design-module-selected {
	border-color: var(--g-color-secondary);
	box-shadow:   0 0 0 1px var(--g-color-secondary);
}

.glass-control-design-module-control--rounded {
	border-radius: 500px;
}

.glass-control-design-module-focusable:focus-within {
	box-shadow:   0 0 0 1px var(--g-color-secondary);
	border-color: var(--g-color-secondary);
}

.glass-control-design-module-hoverable {
	cursor: pointer;
}

.glass-control-design-module-hoverable:hover:not(:focus-within) {
	border-color: var(--g-color-secondary);
}

.glass-control-design-module-clickable {
	cursor: pointer;
}

.glass-control-design-module-disabled {
	background-color: #EDF2F7 !important;
	cursor:           default;
	opacity:          0.6;
}
/*!******************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/textbox/textbox.module.css ***!
  \******************************************************************************************************************/
.glass-textbox-module-textbox {
    width:       100%;
    border:      none;
    background:  transparent;
    box-sizing:  border-box;
    height:      34px;
    line-height: 34px;
    color:       #4A5568;
    padding:     8px 12px;
}

.glass-textbox-module-textbox:focus {
    outline: none !important;
}


.glass-textbox-module-dropdown {
    display: none;
}

.glass-textbox-module-dropdownInner {
    background-color: #ffffff;
    border-radius:    var(--g-rounding);
    box-shadow:       var(--g-shadow);
    overflow-x:       auto;
}

.glass-textbox-module-open {
    display: block;
}

.glass-textbox-module-suggestion {
    display: block;
    cursor:  pointer;
    padding: 8px 8px;
}

.glass-textbox-module-suggestion:hover {
    background-color: var(--g-color-a100);
}
/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/select/select.module.css ***!
  \****************************************************************************************************************/
.glass-select-module-disabled .glass-select-module-valueText {
    cursor: default !important;
}

.glass-select-module-value {
    cursor:      pointer;
    box-sizing:  border-box;
    white-space: nowrap;
}

.glass-select-module-valueText {
    cursor:         pointer;
    caret-color:    transparent;
    color:          inherit;
    outline:        none;
    border:         none;
    line-height:    34px;
    background:     transparent;
    padding:        8px 12px;
    vertical-align: middle;
    display:        inline-block;
    border-radius:  var(--g-rounding);
    width:          calc(100% - 24px);
    height:         34px;
    box-sizing:     border-box;
}

.glass-select-module-valueIcons {
    vertical-align: middle;
    width:          16px;
    text-align:     right;
    display:        inline-block;
    padding-right:  8px;
}

.glass-select-module-valueIcon {
    color:       #A5AEBD;
    font-size:   10px;
    width:       24px;
    line-height: 4px;
    display:     block;
}

.glass-select-module-valueIcon:first-child {
    margin-bottom: -3px;
}

.glass-select-module-valueIcon:last-child {
    margin-top: -3px;
}

.glass-select-module-dropdown {
    display: none;
}

.glass-select-module-inner {
    background-color: #ffffff;
    border-radius:    var(--g-rounding);
    box-shadow:       var(--g-shadow);
    overflow-x:       auto;
}

.glass-select-module-open {
    display: block;
}

.glass-select-module-options {
    max-height: 300px;
    overflow-y: auto;
}

.glass-select-module-option {
    display: block;
    cursor:  pointer;
    padding: 8px 8px;
}

.glass-select-module-optionLabel {
    display: inline-block;
}

.glass-select-module-optionIcon {
    margin-right: 8px;
}

.glass-select-module-option:hover {
    background-color: var(--g-color-a100);
}

.glass-select-module-option svg:nth-child(1) {
    display: inline-block;
}

.glass-select-module-option svg:nth-child(2) {
    display: none;
}

.glass-select-module-optionActive svg:nth-child(1) {
    display: none;
}

.glass-select-module-optionActive svg:nth-child(2) {
    display: inline-block;
}

.glass-select-module-empty {
    padding:    24px 0;
    text-align: center;
}

.glass-select-module-empty {
    display:    block;
    padding:    24px 0;
    text-align: center;
    color:      var(--g-color-n100);
}
/*!******************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/spinner/spinner.module.css ***!
  \******************************************************************************************************************/
.glass-spinner-module-spinner {
    width:               24px;
    height:              24px;
    border:              2px solid var(--g-color-n80);
    border-bottom-color: transparent;
    border-radius:       50%;
    display:             inline-block;
    box-sizing:          border-box;
    animation:           glass-spinner-module-rotation 1s linear infinite;
}

.glass-spinner-module-compact {
    width:               18px;
    height:              18px;
}

@keyframes glass-spinner-module-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/*!**************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/badge/badge.module.css ***!
  \**************************************************************************************************************/
.glass-badge-module-badge {
    padding:       3px 10px;
    border-radius: 500px;
    display:       inline-block;
}

.glass-badge-module-text {
    display:        inline-block;
    font-weight:    600;
    vertical-align: text-bottom;
    font-size:      0.9rem;
}

.glass-badge-module-dot {
    height:         6px;
    width:          6px;
    border-radius:  50%;
    margin-right:   4px;
    display:        inline-block;
    vertical-align: middle;
}

.glass-badge-module-intentInfo {
    background-color: var(--g-color-info-subtle);
    color:            var(--g-color-info-subtle-inverse);
}

.glass-badge-module-intentNeutral {
    background-color: var(--g-color-neutral-subtle);
    color:            var(--g-color-neutral-subtle-inverse);
}

.glass-badge-module-intentDanger {
    background-color: var(--g-color-danger-subtle);
    color:            var(--g-color-danger-subtle-inverse);
}

.glass-badge-module-intentWarning {
    background-color: var(--g-color-warning-subtle);
    color:            var(--g-color-warning-subtle-inverse);
}

.glass-badge-module-intentSuccess {
    background-color: var(--g-color-success-subtle);
    color:            var(--g-color-success-subtle-inverse);
}
/*!********************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/textarea/textarea.module.css ***!
  \********************************************************************************************************************/
.glass-textarea-module-textarea {
    width:      100%;
    border:     none;
    background: transparent;
    box-sizing: border-box;
    padding:    8px 12px;
    resize:     vertical;
    display:    block;
    font-size:  1rem;
    color:      inherit;
}
/*!****************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/image-upload/image-upload.module.css ***!
  \****************************************************************************************************************************/
.glass-image-upload-module-wrapper {
	width:            100%;
	height:           200px;
	background-color: var(--g-color-n20);
	border-radius:    4px;
	text-align:       center;
	overflow:         hidden;
	border:           2px solid var(--g-color-border);
	cursor:           pointer;
	transition:       border 300ms ease;
	position:         relative;
}

.glass-image-upload-module-wrapper:hover {
	border-color: var(--g-color-secondary);
}

.glass-image-upload-module-image {
	max-width: 100%;
	height:    100%;
	display:   block;
	margin:    0 auto;
}

.glass-image-upload-module-placeholder {
	opacity:    0.5;
	margin-top: 60px;
}

.glass-image-upload-module-placeholderIcon {
	display:   block;
	font-size: 60px;
	margin:    0 auto 8px;
}

.glass-image-upload-module-placeholderText {
	display: block;
	margin:  0 auto;
}

.glass-image-upload-module-input {
	opacity:  0.0;
	position: absolute;
	top:      0;
	left:     0;
	bottom:   0;
	right:    0;
	width:    100%;
	height:   100%;
	cursor:   pointer;
}
/*!************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/tabs/tabs.module.css ***!
  \************************************************************************************************************/
.glass-tabs-module-menu {
}

.glass-tabs-module-item {
    border-bottom:  2px solid transparent;
    padding-bottom: 8px;
    margin:         0 16px -1px;
    display:        inline-block;
    font-weight:    500;
    font-size:      15px;
    cursor:         pointer;
}

.glass-tabs-module-item:first-child {
    margin-left: 0;
}

.glass-tabs-module-itemActive {
    color:        var(--g-color-secondary);
    border-color: var(--g-color-secondary);
}
/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/tagger/tagger.module.css ***!
  \****************************************************************************************************************/
.glass-tagger-module-tags {
	margin-top: 8px;
}

.glass-tagger-module-tags:not(:empty) {
	margin-bottom: -4px;
}

.glass-tagger-module-tag {
	background-color: var(--g-color-n20);
	border:           1px solid var(--g-color-border);
	padding:          2px 8px;
	border-radius:    500px;
	margin-bottom:    4px;
	margin-right:     4px;
	display:          inline-block;
}

.glass-tagger-module-tagText {
	display:        inline-block;
	vertical-align: middle;
}

.glass-tagger-module-tagClose {
	vertical-align: middle;
	display:        inline-block;
	margin-left:    4px;
	cursor:         pointer;
	opacity:        0.3;
	transition:     opacity 200ms ease;
}

.glass-tagger-module-tagClose:hover {
	opacity: 0.8;
}

.glass-tagger-module-tagCloseIcon {
	display:        block;
	vertical-align: middle;
	line-height:    16px;
	font-size:      16px;
}
/*!**********************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/infinity-scroll/infinity-scroll.module.css ***!
  \**********************************************************************************************************************************/
.glass-infinity-scroll-module-infinite {
	overflow-x: scroll;
	display:    flex;
	flex-wrap:  nowrap;
}

.glass-infinity-scroll-module-infinite > div {
	flex-shrink: 0;
	flex-grow:   0;
}

.glass-infinity-scroll-module-alignTop {
	align-items: flex-start;
}

.glass-infinity-scroll-module-alignCenter {
	align-items: center;
}

.glass-infinity-scroll-module-alignBottom {
	align-items: flex-end;
}
/*!********************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/skeleton/skeleton.module.css ***!
  \********************************************************************************************************************/
.glass-skeleton-module-skeleton {
	background-color: black;
	opacity:          0.07;
	display:          block;
	border-radius:    var(--g-rounding);
}
/*!************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/list/list.module.css ***!
  \************************************************************************************************************/
.glass-list-module-list {
	margin-left: 32px;
}

ul.glass-list-module-list {
	list-style: disc;
}

ol.glass-list-module-list {
	list-style: decimal;
}

.glass-list-module-item {
	padding: 0;
	margin:  0 0 8px 0;
}
/*!******************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/listbox/listbox.module.css ***!
  \******************************************************************************************************************/
.glass-listbox-module-listbox {
    border-radius:    var(--g-rounding);
    background-color: var(--g-color-n20);
    border:           1px solid var(--g-color-border);
}

.glass-listbox-module-search {
    border-top-left-radius:  var(--g-rounding);
    border-top-right-radius: var(--g-rounding);
    display:                 flex;
    align-items:             center;
    background-color:        white;
    overflow:                hidden;
    border-bottom:           1px solid var(--g-color-border);
}

.glass-listbox-module-searchClear {
    cursor:       pointer;
    color:        var(--g-color-n50);
    transition:   color 200ms ease;
    margin-right: 16px;
}

.glass-listbox-module-searchClear:hover {
    color: var(--g-color-n100);
}

.glass-listbox-module-searchInput {
    border:      none;
    width:       100%;
    box-sizing:  border-box;
    height:      34px;
    line-height: 34px;
    color:       #4A5568;
    padding:     8px 12px;
}

.glass-listbox-module-search:focus {
    outline: none !important;
}

.glass-listbox-module-items {
    height:     200px;
    overflow-y: auto;
    max-height: 200px;
}

.glass-listbox-module-item {
    background-color: var(--g-color-n0);
    border-bottom:    1px solid var(--g-color-n30);
    padding:          8px;
    display:          flex;
    justify-content:  space-between;
    vertical-align:   middle;
    cursor:           pointer;
    line-height:      18px;
}

.glass-listbox-module-item:hover {
    background-color: var(--g-color-n10);
}

.glass-listbox-module-select {
    cursor:     pointer;
    opacity:    0.3;
    transition: opacity 200ms ease;
}

.glass-listbox-module-selectedIcon {
    color:          var(--g-color-secondary);
    display:        block;
    vertical-align: middle;
    line-height:    18px;
    font-size:      18px;
}

.glass-listbox-module-empty {
    display:         flex;
    justify-content: center;
    align-items:     center;
    height:          200px;
}

.glass-listbox-module-emptyText {
    color: var(--g-color-n300);
}
/*!**********************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/separator/separator.module.css ***!
  \**********************************************************************************************************************/
.glass-separator-module-horizontal {
    border-bottom: 1px solid var(--g-color-border);
    width:         100%;
    min-width:     100%;
}

.glass-separator-module-vertical {
    align-self:   stretch;
    height:       100%;
    min-height:   100%;
    border-right: 1px solid var(--g-color-border);
}
/*!**************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/alert/alert.module.css ***!
  \**************************************************************************************************************/
.glass-alert-module-alert {
    padding:       calc(2 * var(--g-space));
    border-radius: var(--g-rounding);
    display:       flex;
    flex-wrap:     nowrap;
}

.glass-alert-module-icon {
    flex-shrink:  0;
    flex-grow:    0;
    margin-right: calc(2 * var(--g-space));
}

.glass-alert-module-intentInfo {
    background-color: var(--g-color-info-subtle);
    color:            var(--g-color-info-subtle-inverse);
}

.glass-alert-module-intentNeutral {
    background-color: var(--g-color-neutral-subtle);
    color:            var(--g-color-neutral-subtle-inverse);
}

.glass-alert-module-intentDanger {
    background-color: var(--g-color-danger-subtle);
    color:            var(--g-color-danger-subtle-inverse);
}

.glass-alert-module-intentWarning {
    background-color: var(--g-color-warning-subtle);
    color:            var(--g-color-warning-subtle-inverse);
}

.glass-alert-module-intentSuccess {
    background-color: var(--g-color-success-subtle);
    color:            var(--g-color-success-subtle-inverse);
}
/*!******************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/popover/popover.module.css ***!
  \******************************************************************************************************************/
.glass-popover-module-wrapper {
    display:  inline-block;
}

.glass-popover-module-content {
    background:    #ffffff;
    box-shadow:    var(--g-shadow);
    border:        1px solid var(--g-color-n20);
    border-radius: var(--g-rounding);
}
/*!*************************************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/@fortawesome/fontawesome-svg-core/styles.css ***!
  \*************************************************************************************************************************************************/
:root, :host {
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Solid';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Regular';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Light';
  --fa-font-thin: normal 100 1em/1 'Font Awesome 6 Thin';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone';
  --fa-font-sharp-solid: normal 900 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-regular: normal 400 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-light: normal 300 1em/1 'Font Awesome 6 Sharp';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }

svg:not(:root).glass-styles-svg-inline--fa, svg:not(:host).glass-styles-svg-inline--fa {
  overflow: visible;
  box-sizing: content-box; }

.glass-styles-svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -.125em; }
  .glass-styles-svg-inline--fa.glass-styles-fa-2xs {
    vertical-align: 0.1em; }
  .glass-styles-svg-inline--fa.glass-styles-fa-xs {
    vertical-align: 0em; }
  .glass-styles-svg-inline--fa.glass-styles-fa-sm {
    vertical-align: -0.07143em; }
  .glass-styles-svg-inline--fa.glass-styles-fa-lg {
    vertical-align: -0.2em; }
  .glass-styles-svg-inline--fa.glass-styles-fa-xl {
    vertical-align: -0.25em; }
  .glass-styles-svg-inline--fa.glass-styles-fa-2xl {
    vertical-align: -0.3125em; }
  .glass-styles-svg-inline--fa.glass-styles-fa-pull-left {
    margin-right: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .glass-styles-svg-inline--fa.glass-styles-fa-pull-right {
    margin-left: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .glass-styles-svg-inline--fa.glass-styles-fa-li {
    width: var(--fa-li-width, 2em);
    top: 0.25em; }
  .glass-styles-svg-inline--fa.glass-styles-fa-fw {
    width: var(--fa-fw-width, 1.25em); }

.glass-styles-fa-layers svg.glass-styles-svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.glass-styles-fa-layers-text, .glass-styles-fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center; }

.glass-styles-fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -.125em;
  width: 1em; }
  .glass-styles-fa-layers svg.glass-styles-svg-inline--fa {
    -webkit-transform-origin: center center;
            transform-origin: center center; }

.glass-styles-fa-layers-text {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
          transform-origin: center center; }

.glass-styles-fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: var(--fa-counter-padding, 0.25em 0.5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-counter-scale, 0.25));
          transform: scale(var(--fa-counter-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right; }

.glass-styles-fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right; }

.glass-styles-fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left; }

.glass-styles-fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right; }

.glass-styles-fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top left;
          transform-origin: top left; }

.glass-styles-fa-1x {
  font-size: 1em; }

.glass-styles-fa-2x {
  font-size: 2em; }

.glass-styles-fa-3x {
  font-size: 3em; }

.glass-styles-fa-4x {
  font-size: 4em; }

.glass-styles-fa-5x {
  font-size: 5em; }

.glass-styles-fa-6x {
  font-size: 6em; }

.glass-styles-fa-7x {
  font-size: 7em; }

.glass-styles-fa-8x {
  font-size: 8em; }

.glass-styles-fa-9x {
  font-size: 9em; }

.glass-styles-fa-10x {
  font-size: 10em; }

.glass-styles-fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.glass-styles-fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.glass-styles-fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.glass-styles-fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.glass-styles-fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.glass-styles-fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.glass-styles-fa-fw {
  text-align: center;
  width: 1.25em; }

.glass-styles-fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .glass-styles-fa-ul > li {
    position: relative; }

.glass-styles-fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.glass-styles-fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.glass-styles-fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.glass-styles-fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.glass-styles-fa-beat {
  -webkit-animation-name: glass-styles-fa-beat;
          animation-name: glass-styles-fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.glass-styles-fa-bounce {
  -webkit-animation-name: glass-styles-fa-bounce;
          animation-name: glass-styles-fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.glass-styles-fa-fade {
  -webkit-animation-name: glass-styles-fa-fade;
          animation-name: glass-styles-fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.glass-styles-fa-beat-fade {
  -webkit-animation-name: glass-styles-fa-beat-fade;
          animation-name: glass-styles-fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.glass-styles-fa-flip {
  -webkit-animation-name: glass-styles-fa-flip;
          animation-name: glass-styles-fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.glass-styles-fa-shake {
  -webkit-animation-name: glass-styles-fa-shake;
          animation-name: glass-styles-fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear); }

.glass-styles-fa-spin {
  -webkit-animation-name: glass-styles-fa-spin;
          animation-name: glass-styles-fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
          animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear); }

.glass-styles-fa-spin-reverse {
  --fa-animation-direction: reverse; }

.glass-styles-fa-pulse,
.glass-styles-fa-spin-pulse {
  -webkit-animation-name: glass-styles-fa-spin;
          animation-name: glass-styles-fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
          animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .glass-styles-fa-beat,
  .glass-styles-fa-bounce,
  .glass-styles-fa-fade,
  .glass-styles-fa-beat-fade,
  .glass-styles-fa-flip,
  .glass-styles-fa-pulse,
  .glass-styles-fa-shake,
  .glass-styles-fa-spin,
  .glass-styles-fa-spin-pulse {
    -webkit-animation-delay: -1ms;
            animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
            animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-duration: 0s;
            transition-duration: 0s; } }

@-webkit-keyframes glass-styles-fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes glass-styles-fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@-webkit-keyframes glass-styles-fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@keyframes glass-styles-fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@-webkit-keyframes glass-styles-fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes glass-styles-fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@-webkit-keyframes glass-styles-fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes glass-styles-fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@-webkit-keyframes glass-styles-fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes glass-styles-fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@-webkit-keyframes glass-styles-fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg); }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg); }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg); }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg); }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg); }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg); }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes glass-styles-fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg); }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg); }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg); }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg); }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg); }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg); }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes glass-styles-fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes glass-styles-fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.glass-styles-fa-rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.glass-styles-fa-rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.glass-styles-fa-rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.glass-styles-fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.glass-styles-fa-flip-vertical {
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.glass-styles-fa-flip-both,
.glass-styles-fa-flip-horizontal.glass-styles-fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

.glass-styles-fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
          transform: rotate(var(--fa-rotate-angle, none)); }

.glass-styles-fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em; }

.glass-styles-fa-stack-1x,
.glass-styles-fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--fa-stack-z-index, auto); }

.glass-styles-svg-inline--fa.glass-styles-fa-stack-1x {
  height: 1em;
  width: 1.25em; }

.glass-styles-svg-inline--fa.glass-styles-fa-stack-2x {
  height: 2em;
  width: 2.5em; }

.glass-styles-fa-inverse {
  color: var(--fa-inverse, #fff); }

.glass-styles-sr-only,
.glass-styles-fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.glass-styles-sr-only-focusable:not(:focus),
.glass-styles-fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.glass-styles-svg-inline--fa .glass-styles-fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1); }

.glass-styles-svg-inline--fa .glass-styles-fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, 0.4); }

.glass-styles-svg-inline--fa.glass-styles-fa-swap-opacity .glass-styles-fa-primary {
  opacity: var(--fa-secondary-opacity, 0.4); }

.glass-styles-svg-inline--fa.glass-styles-fa-swap-opacity .glass-styles-fa-secondary {
  opacity: var(--fa-primary-opacity, 1); }

.glass-styles-svg-inline--fa mask .glass-styles-fa-primary,
.glass-styles-svg-inline--fa mask .glass-styles-fa-secondary {
  fill: black; }

.glass-styles-fad.glass-styles-fa-inverse,
.glass-styles-fa-duotone.glass-styles-fa-inverse {
  color: var(--fa-inverse, #fff); }

/*!********************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/boxed-selector/boxed-selector.module.css ***!
  \********************************************************************************************************************************/
.glass-boxed-selector-module-selector {
    display:   flex;
    flex-wrap: nowrap;
    gap:       8px;
}

.glass-boxed-selector-module-dirVertical {
    flex-direction: column;
}

.glass-boxed-selector-module-item {
    flex-grow:     1;
    flex-shrink:   0;
    flex-basis:    0;
    border-radius: var(--g-rounding);
}

.glass-boxed-selector-module-itemActive .glass-boxed-selector-module-itemInner {
    font-weight: 500;
    color:       var(--g-color-secondary);
}

.glass-boxed-selector-module-itemInner {
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
}

.glass-boxed-selector-module-itemChildren {
    flex-grow: 1;
}

.glass-boxed-selector-module-itemIndicator {
    flex-grow:   0;
    flex-shrink: 0;
}

/*!******************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/tooltip/tooltip.module.css ***!
  \******************************************************************************************************************/
.glass-tooltip-module-reference {
    display:     inline-block;
    line-height: 0;
}

.glass-tooltip-module-content {
    background-color: var(--g-color-n700);
    color:            white;
    padding:          4px 6px;
    max-width:        200px;
    border-radius:    var(--g-rounding);
    z-index:          1;
}
/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/reveal/reveal.module.css ***!
  \****************************************************************************************************************/
.glass-reveal-module-reveal {
}

.glass-reveal-module-hidden {
    opacity:    0;
    transition: opacity 200ms ease;
}

.glass-reveal-module-reveal:hover .glass-reveal-module-hidden {
    opacity: 1;
}
/*!************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/code/code.module.css ***!
  \************************************************************************************************************/
.glass-code-module-code {
    background-color: var(--g-color-n20);
    padding:          4px;
    border:           1px solid var(--g-color-n30);
    border-radius:    var(--g-rounding);
    user-select:      all;
    display:          inline-block;
    font-size:        0.9em;
    word-break:       break-word;
}
/*!************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/code-block/code-block.module.css ***!
  \************************************************************************************************************************/
.glass-code-block-module-code {
    background-color: var(--g-color-n20);
    padding:          16px;
    border:           1px solid var(--g-color-n30);
    border-radius:    var(--g-rounding);
    user-select:      all;
    display:          block;
    font-family:      monospace;
}

.glass-code-block-module-pre {
    margin:  0;
    padding: 0;
}
/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/spaced/spaced.module.css ***!
  \****************************************************************************************************************/
.glass-spaced-module-item:last-child {
    margin: 0;
}

.glass-spaced-module-spaced {
    display: flex;
    flex-wrap: nowrap;
}

.glass-spaced-module-directionHorizontal {
    flex-direction: row;
}

.glass-spaced-module-directionVertical {
    flex-direction: column;
}
/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/filter/filter.module.css ***!
  \****************************************************************************************************************/
.glass-filter-module-filter {
    border:        1px solid var(--g-color-border);
    padding:       4px 8px 4px 8px;
    border-radius: 500px;
    display:       inline-flex;
    align-items:   center;
    cursor:        pointer;
    background-color: var(--g-color-n0);
}

.glass-filter-module-filter:hover {
    transition: border 200ms ease;
    border-color: var(--g-color-secondary);
}

.glass-filter-module-active {
    border-style: solid;
}

.glass-filter-module-action {
    margin-right: 8px;
    line-height:  0;
}

.glass-filter-module-action svg:nth-child(1) {
    display: inline-block;
}

.glass-filter-module-action svg:nth-child(2) {
    display: none;
}

.glass-filter-module-actionActive svg:nth-child(1) {
    display: none;
}

.glass-filter-module-actionActive svg:nth-child(2) {
    display: inline-block;
}

.glass-filter-module-icon {
    color:      var(--g-color-border);
    font-size:  0.8rem;
    display:    block;
    transition: color 200ms ease;
}

.glass-filter-module-icon:hover {
    color: var(--g-color-n100);
}

.glass-filter-module-label {
    font-size:      0.9rem;
    color:          var(--g-color-n400);
    font-weight:    500;
    letter-spacing: 0;
}

.glass-filter-module-separator {
    border-left:  1px solid var(--g-color-n80);
    margin-left:  8px;
    margin-right: 8px;
    height:       12px;
}

.glass-filter-module-value {
    font-size:   0.9rem;
    font-weight: 500;
    color:       var(--g-color-secondary);
}
/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/wizard/wizard.module.css ***!
  \****************************************************************************************************************/
.glass-wizard-module-wizard {
    background: white;
    z-index:    1;
    top:        0;
    right:      0;
    bottom:     0;
    left:       0;
    position:   fixed;
}

.glass-wizard-module-body {
    margin-top: 100px;
    max-width:    550px;
    margin-left:  auto;
    margin-right: auto;
}

.glass-wizard-module-header {
    border-bottom: 1px solid var(--g-color-border);
    padding:       8px 24px;
    display:       flex;
    align-items:   center;
}

.glass-wizard-module-headerClose {
    margin-left:     -8px;
    display:         flex;
    justify-content: center;
    align-items:     center;
    padding:         8px;
    cursor:          pointer;
    transition:      background-color 200ms ease;
    border-radius:   500px;
    font-size:       18px;
    height:          1rem;
    color:           var(--g-color-n300);
}

.glass-wizard-module-headerClose:hover {
    background-color: var(--g-color-n20);
}

.glass-wizard-module-headerTitle {
    margin-left: 4px;
    font-weight: 600;
}

.glass-wizard-module-headerActions {
    margin-left: auto;
}
/*!**********************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/accordion/accordion.module.css ***!
  \**********************************************************************************************************************/
.glass-accordion-module-accordion {

}

.glass-accordion-module-header {
    display:         flex;
    justify-content: space-between;
    padding:         16px;
    cursor:          pointer;
}

.glass-accordion-module-title {
    font-weight: 600;
}

.glass-accordion-module-content {
    padding: 0 16px 16px 16px;
}

.glass-accordion-module-icon {
    transition: all 200ms ease;
}

.glass-accordion-module-iconRotated {
    transform: rotate(180deg);
}
/*!**************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/glass/glass.module.css ***!
  \**************************************************************************************************************/
input,
textarea,
select,
button,
:root {
    font-family:             -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                             'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                             sans-serif;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline:                 none !important;
}

body {
    margin:  0;
    padding: 0;
}

:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

body {
    margin:     0;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    display:    block;
    margin-top: 0;
}

p {
    margin: 0;
}

ul, ol {
    list-style: none;
    padding:    0;
    margin:     0;
}

li {
    padding: 0;
}

button {
    font: inherit;
}

input {
    font-size: 1rem;
}

a {
    text-decoration: none;
    outline:         none;
}


button:focus, input:focus, a:focus, textarea:focus,
button, input, a, textarea {
    outline: none;
}

:root {
    --g-color-n0:                     #FFFFFF;
    --g-color-n10:                    #FAFBFC;
    --g-color-n20:                    #F5F5F7;
    --g-color-n30:                    #EBECF0;
    --g-color-n40:                    #DFE1E6;
    --g-color-n50:                    #C1C7D0;

    --g-color-n60:                    #B3BAC5;
    --g-color-n70:                    #A5ADBA;
    --g-color-n80:                    #97A0AF;
    --g-color-n90:                    #8993A4;
    --g-color-n100:                   #7A869A;

    --g-color-n200:                   #6B778C;
    --g-color-n300:                   #5E6C84;
    --g-color-n400:                   #505F79;

    --g-color-n500:                   #42526E;
    --g-color-n600:                   #344563;
    --g-color-n700:                   #2B3950;
    --g-color-n800:                   #222D38;
    --g-color-n900:                   #12191F;

    --g-color-a100:                   rgba(52, 69, 99, 0.04);
    --g-color-a200:                   rgba(52, 69, 99, 0.08);
    --g-color-a300:                   rgba(52, 69, 99, 0.15);
    --g-color-a400:                   rgba(52, 69, 99, 0.2);
    --g-color-a500:                   rgba(52, 69, 99, 0.27);

    --g-color-red100:                 #ffe4e9;
    --g-color-red200:                 #fcc3cb;
    --g-color-red300:                 #f58c9e;
    --g-color-red400:                 #ee4c6d;
    --g-color-red500:                 #e12449;
    --g-color-red600:                 #c91235;
    --g-color-red700:                 #ad0b2a;
    --g-color-red800:                 #80061d;
    --g-color-red900:                 #4f0110;
    --g-color-red1000:                #3b010b;

    --g-color-yel100:                 #FFF7D6;
    --g-color-yel200:                 #F8E6A0;
    --g-color-yel300:                 #F4CC46;
    --g-color-yel400:                 #E2B203;
    --g-color-yel500:                 #CF9F02;
    --g-color-yel600:                 #B38600;
    --g-color-yel700:                 #946F00;
    --g-color-yel800:                 #7F5F01;
    --g-color-yel900:                 #533F04;
    --g-color-yel1000:                #3D2E00;

    --g-color-gre100:                 #DFFCF0;
    --g-color-gre200:                 #BAF3DB;
    --g-color-gre300:                 #7EE2B8;
    --g-color-gre400:                 #4BCE97;
    --g-color-gre500:                 #2ABB7F;
    --g-color-gre600:                 #22A06B;
    --g-color-gre700:                 #1F845A;
    --g-color-gre800:                 #216E4E;
    --g-color-gre900:                 #164B35;
    --g-color-gre1000:                #133527;

    --g-color-blu100:                 #E9F2FF;
    --g-color-blu200:                 #CCE0FF;
    --g-color-blu300:                 #84B8FF;
    --g-color-blu400:                 #579DFF;
    --g-color-blu500:                 #388BFF;
    --g-color-blu600:                 #1D7AFC;
    --g-color-blu700:                 #0C66E4;
    --g-color-blu800:                 #0055CC;
    --g-color-blu900:                 #08316B;
    --g-color-blu1000:                #082145;

    --g-color-pur100:                 #F3F0FF;
    --g-color-pur200:                 #DFD8FD;
    --g-color-pur300:                 #B8ACF6;
    --g-color-pur400:                 #9F8FEF;
    --g-color-pur500:                 #8F7EE7;
    --g-color-pur600:                 #8270DB;
    --g-color-pur700:                 #6E5DC6;
    --g-color-pur800:                 #5E4DB2;
    --g-color-pur900:                 #352C63;
    --g-color-pur1000:                #231C3F;

    /* Box shadow for floating components */
    --g-shadow:                       0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    /* Spacing */
    --g-space:                        8px;
    --g-size-0:                       0;
    --g-size-0-5:                     4px;
    --g-size-1:                       8px;
    --g-size-1-5:                     12px;
    --g-size-2:                       16px;
    --g-size-3:                       24px;
    --g-size-4:                       32px;
    --g-size-6:                       48px;
    --g-size-8:                       64px;
    --g-size-12:                      96px;
    --g-size-16:                      128px;

    /* Tokens */
    --g-color-brand-purple: #6c5fc7;
    --g-color-brand-sand: #f5f2f2;

    --g-color-primary:                #59365e;
    --g-color-secondary:              #6c5fc7;
    --g-color-tertiary:               #f5f2f2;

    /* Text Colors */
    --g-color-text:                   var(--g-color-n900);
    --g-color-text-subtle:            var(--g-color-n100);
    --g-color-text-link:              var(--g-color-blu600);

    /* Contextual Colors*/
    --g-color-neutral:                var(--g-color-n40);
    --g-color-neutral-inverse:        var(--g-color-n700);
    --g-color-neutral-subtle:         var(--g-color-n20);
    --g-color-neutral-subtle-inverse: var(--g-color-n300);

    --g-color-success:                var(--g-color-gre500);
    --g-color-success-inverse:        var(--g-color-n0);
    --g-color-success-subtle:         var(--g-color-gre100);
    --g-color-success-subtle-inverse: var(--g-color-gre600);

    --g-color-warning:                var(--g-color-yel400);
    --g-color-warning-inverse:        var(--g-color-n900);
    --g-color-warning-subtle:         var(--g-color-yel100);
    --g-color-warning-subtle-inverse: var(--g-color-yel600);

    --g-color-info:                   var(--g-color-blu500);
    --g-color-info-inverse:           var(--g-color-n0);
    --g-color-info-subtle:            var(--g-color-blu100);
    --g-color-info-subtle-inverse:    var(--g-color-blu600);

    --g-color-danger:                 var(--g-color-red500);
    --g-color-danger-inverse:         var(--g-color-n0);
    --g-color-danger-subtle:          var(--g-color-red100);
    --g-color-danger-subtle-inverse:  var(--g-color-red600);

    --g-color-border:                 var(--g-color-n30);

    /* How extreme rounded elements are */
    --g-rounding:                     6px;
}
/*!************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/menu/menu.module.css ***!
  \************************************************************************************************************/
.glass-menu-module-reference {
    display: inline-block;
}

.glass-menu-module-menu {
    border-radius:    var(--g-rounding);
    background-color: var(--g-color-n0);
    box-shadow:       2px 2px 8px rgba(0, 0, 0, .06);
    width:            200px;
    box-sizing:       border-box;
    overflow:         hidden;
    z-index:          1;
}

.glass-menu-module-item {
    padding:       8px 16px;
    cursor:        pointer;
    border-radius: var(--g-rounding);
}

.glass-menu-module-text {
    font-weight: 500;
}

.glass-menu-module-item:hover {
    background: var(--g-color-n20);
}

.glass-menu-module-group {
    padding: 4px;
}

.glass-menu-module-group + .glass-menu-module-group {
    border-top:  1px solid var(--g-color-border);
}

.glass-menu-module-icon {
    margin-right: 8px;
}
/*!********************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/slideout/slideout.module.css ***!
  \********************************************************************************************************************/
.glass-slideout-module-slideout {
    background:     white;
    box-shadow:     0 3px 6px 0 rgba(0, 0, 0, 0.12), 0px 7px 14px 0px rgba(60, 66, 87, 0.08);
    width:          25vw;
    min-width:      450px;
    z-index:        1;
    border-radius:  var(--g-rounding);
    right:          0;
    margin:         8px;
    position:       absolute;
    height:         calc(100vh - 16px);
    display:        flex;
    flex-direction: column;
}

.glass-slideout-module-group {
    padding: 24px;
}

.glass-slideout-module-group + .glass-slideout-module-group {
    border-top: 1px solid var(--g-color-border);
}

.glass-slideout-module-header {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    padding:         12px 24px;
    border-bottom:   1px solid var(--g-color-border);
    flex-grow:       0;
    flex-shrink:     0;
}

.glass-slideout-module-headerTitle {
    font-size:   20px;
    line-height: 1;
    font-weight: 700;
    display:     block;
}

.glass-slideout-module-headerClose {
    display: block;
}

.glass-slideout-module-headerCloseIcon {
    font-size:     22px;
    line-height:   22px;
    cursor:        pointer;
    text-align:    center;
    color:         var(--g-color-n100);
    transition:    opacity 300ms ease;
    border-radius: var(--g-rounding);
    width:         22px;
    height:        22px;
    padding:       8px;
}

.glass-slideout-module-headerCloseIcon:hover {
    background-color: var(--g-color-n20);
    cursor:           pointer;
}

.glass-slideout-module-body {
    overflow-y: auto;
}

.glass-slideout-module-footer {
    background: white;
    padding:     16px;
    flex-grow:   0;
    flex-shrink: 0;
    margin-top:  auto;
}

/*!**********************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/show-more/show-more.module.css ***!
  \**********************************************************************************************************************/
.glass-show-more-module-showmore {

}

.glass-show-more-module-expander {
    display:     inline-flex;
    align-items: center;
    cursor:      pointer;
}

.glass-show-more-module-title {
    color:        var(--g-color-secondary);
    flex-grow:    0;
    flex-shrink:  0;
    margin-right: 4px;
}

.glass-show-more-module-icon {
    color: var(--g-color-secondary);
}

.glass-show-more-module-iconRotated {
    transform: rotate(180deg);
}

.glass-show-more-module-content {
    transition: all 200ms ease;
}
/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/avatar/avatar.module.css ***!
  \****************************************************************************************************************/
.glass-avatar-module-avatar {
    border-radius:    500px;
    height:           32px;
    width:            32px;
    display:          flex;
    justify-content:  center;
    align-items:      center;
    background-color: var(--g-color-n20);
}

.glass-avatar-module-image {
    height: 100%;
    width:  100%;
}

.glass-avatar-module-initials {
    font-size: 12px;
}

.glass-avatar-module-icon {
    max-height: 1rem;
    height:     1rem;
    font-size:  1rem;
}
/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/switch/switch.module.css ***!
  \****************************************************************************************************************/
.glass-switch-module-switch {
    position: relative;
    display:  inline-block;
    width:    46px;
    height:   25px;
}

.glass-switch-module-input {
    opacity: 0;
    width:   0;
    height:  0;
}

.glass-switch-module-slider {
    position:           absolute;
    cursor:             pointer;
    top:                0;
    left:               0;
    right:              0;
    bottom:             0;
    background-color:   #CCCCCC;
    -webkit-transition: .4s;
    transition:         .4s;
    border-radius:      34px;
}

.glass-switch-module-slider:before {
    position:           absolute;
    content:            "";
    height:             18px;
    width:              18px;
    left:               4px;
    bottom:             4px;
    background-color:   white;
    -webkit-transition: .4s;
    transition:         .4s;
    border-radius:      50%;
}

.glass-switch-module-input:checked + .glass-switch-module-slider {
    background-color: var(--g-color-success);
}

.glass-switch-module-input:focus + .glass-switch-module-slider {
    box-shadow: 0 0 1px var(--g-color-success);
}

.glass-switch-module-input:checked + .glass-switch-module-slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform:     translateX(20px);
    transform:         translateX(20px);
}
/*!****************************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/progress-bar/progress-bar.module.css ***!
  \****************************************************************************************************************************/
.glass-progress-bar-module-bar {
    width:            100%;
    background-color: var(--g-color-n10);
    height:           8px;
    overflow:         hidden;
}

.glass-progress-bar-module-fill {
    height: 8px;
    background-color: var(--g-color-success);
}

.glass-progress-bar-module-pill {
    border-radius: 500px;
}
/*!****************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/effect/effect.module.css ***!
  \****************************************************************************************************************/
.glass-effect-module-effect {
    transition: background-color 200ms ease, border 200ms ease;
}

.glass-effect-module-hoverable:hover {
    background-color: var(--g-color-n20);
}

.glass-effect-module-clickable:hover {
    cursor: pointer;
    background-color: var(--g-color-n20);
}

.glass-effect-module-selectable:hover {
    border-color: var(--g-color-brand-purple);
}
/*!************************************************************************************************************!*\
  !*** css ../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./src/card/card.module.css ***!
  \************************************************************************************************************/
.glass-card-module-card {
    border-radius: 6px;
    border:        1px solid var(--g-color-border);
    padding:       16px;
}

.glass-card-module-clickable {
    cursor: pointer;
}

.glass-card-module-clickable:hover {
    background-color: var(--g-color-n10);
    transition:       background-color 200ms ease;
}

/*# sourceMappingURL=main.css.map*/