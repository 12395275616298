.filter {
    border:        1px solid var(--g-color-border);
    padding:       4px 8px 4px 8px;
    border-radius: 500px;
    display:       inline-flex;
    align-items:   center;
    cursor:        pointer;
    background-color: var(--g-color-n0);
}

.filter:hover {
    transition: border 200ms ease;
    border-color: var(--g-color-secondary);
}

.active {
    border-style: solid;
}

.action {
    margin-right: 8px;
    line-height:  0;
}

.action svg:nth-child(1) {
    display: inline-block;
}

.action svg:nth-child(2) {
    display: none;
}

.actionActive svg:nth-child(1) {
    display: none;
}

.actionActive svg:nth-child(2) {
    display: inline-block;
}

.icon {
    color:      var(--g-color-border);
    font-size:  0.8rem;
    display:    block;
    transition: color 200ms ease;
}

.icon:hover {
    color: var(--g-color-n100);
}

.label {
    font-size:      0.9rem;
    color:          var(--g-color-n400);
    font-weight:    500;
    letter-spacing: 0;
}

.separator {
    border-left:  1px solid var(--g-color-n80);
    margin-left:  8px;
    margin-right: 8px;
    height:       12px;
}

.value {
    font-size:   0.9rem;
    font-weight: 500;
    color:       var(--g-color-secondary);
}