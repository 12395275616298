.slideout {
    background:     white;
    box-shadow:     0 3px 6px 0 rgba(0, 0, 0, 0.12), 0px 7px 14px 0px rgba(60, 66, 87, 0.08);
    width:          25vw;
    min-width:      450px;
    z-index:        1;
    border-radius:  var(--g-rounding);
    right:          0;
    margin:         8px;
    position:       absolute;
    height:         calc(100vh - 16px);
    display:        flex;
    flex-direction: column;
}

.group {
    padding: 24px;
}

.group + .group {
    border-top: 1px solid var(--g-color-border);
}

.header {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    padding:         12px 24px;
    border-bottom:   1px solid var(--g-color-border);
    flex-grow:       0;
    flex-shrink:     0;
}

.headerTitle {
    font-size:   20px;
    line-height: 1;
    font-weight: 700;
    display:     block;
}

.headerClose {
    display: block;
}

.headerCloseIcon {
    font-size:     22px;
    line-height:   22px;
    cursor:        pointer;
    text-align:    center;
    color:         var(--g-color-n100);
    transition:    opacity 300ms ease;
    border-radius: var(--g-rounding);
    width:         22px;
    height:        22px;
    padding:       8px;
}

.headerCloseIcon:hover {
    background-color: var(--g-color-n20);
    cursor:           pointer;
}

.body {
    overflow-y: auto;
}

.footer {
    background: white;
    padding:     16px;
    flex-grow:   0;
    flex-shrink: 0;
    margin-top:  auto;
}
