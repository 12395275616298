.textbox {
    width:       100%;
    border:      none;
    background:  transparent;
    box-sizing:  border-box;
    height:      34px;
    line-height: 34px;
    color:       #4A5568;
    padding:     8px 12px;
}

.textbox:focus {
    outline: none !important;
}


.dropdown {
    display: none;
}

.dropdownInner {
    background-color: #ffffff;
    border-radius:    var(--g-rounding);
    box-shadow:       var(--g-shadow);
    overflow-x:       auto;
}

.open {
    display: block;
}

.suggestion {
    display: block;
    cursor:  pointer;
    padding: 8px 8px;
}

.suggestion:hover {
    background-color: var(--g-color-a100);
}