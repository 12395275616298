.code {
    background-color: var(--g-color-n20);
    padding:          16px;
    border:           1px solid var(--g-color-n30);
    border-radius:    var(--g-rounding);
    user-select:      all;
    display:          block;
    font-family:      monospace;
}

.pre {
    margin:  0;
    padding: 0;
}