.bar {
    width:            100%;
    background-color: var(--g-color-n10);
    height:           8px;
    overflow:         hidden;
}

.fill {
    height: 8px;
    background-color: var(--g-color-success);
}

.pill {
    border-radius: 500px;
}