.table {
    table-layout:     auto;
    width:            100%;
    border-collapse:  separate;
    border:           1px solid var(--g-color-border);
    border-spacing:   0;
    border-bottom:    none;
    border-radius:    var(--g-rounding);
    position:         relative;
    z-index:          0;
    overflow:         hidden;
    background-color: var(--g-color-n0);
}

.embedded {
    border:        none;
    border-radius: 0;
}

.embedded thead {
    display: none;
}

.header {
    position:         sticky;
    background-color: var(--g-color-n0);
    top:              0;
    z-index:          1;
}

.headerCell {
    text-align:    left;
    padding:       var(--g-size-1);
    font-weight:   500;
    border-bottom: 1px solid var(--g-color-border);
}

.bodyCell {
    padding:       var(--g-size-1);
    overflow:      hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid var(--g-color-border);
}

.bodyCellShrink {
    width:       0.1%;
    white-space: nowrap;
}

.row {
    transition: background-color 200ms ease;
}

.row:not(:last-child) {
    border-bottom: 1px solid Var(--g-color-border);
}

.rowClickable:hover {
    background-color: var(--g-color-n10);
    cursor:           pointer;
}

.empty {
    padding:    32px 0 24px 0;
    text-align: center;
}

.emptyIcon {
    display:   inline-block;
    font-size: 32px;
    color:     var(--g-color-border);
    margin:    0 auto 16px auto;
}

.emptyMessage {
    display: block;
    color:   var(--g-color-border);
}