.accordion {

}

.header {
    display:         flex;
    justify-content: space-between;
    padding:         16px;
    cursor:          pointer;
}

.title {
    font-weight: 600;
}

.content {
    padding: 0 16px 16px 16px;
}

.icon {
    transition: all 200ms ease;
}

.iconRotated {
    transform: rotate(180deg);
}