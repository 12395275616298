.button {
    cursor:        pointer;
    display:       inline-block;
    line-height:   1em;
    border:        1px solid transparent;
    transition:    opacity 200ms ease, background-color 200ms ease, border 200ms ease;
    border-radius: var(--g-rounding);
}

.buttonWithIcon .children {
    margin-left: 8px;
}

.children {
    display:     inline-block;
    font-weight: 600;
    color:       inherit;
}

.button + .button {
    margin-left: 10px;
}

.varPrimary {
    color:            var(--g-color-n0);
    border-color:     var(--g-color-secondary);
    background-color: var(--g-color-secondary);
}

.varPrimary:hover {
    background-color: #594daf;
    border-color:     #594daf;
}

.varDefault {
    color:            var(--g-color-n700);
    background-color: var(--g-color-n0);
    border-color:     var(--g-color-n30);
}

.varDefault:hover {
    background-color: var(--g-color-n10);
}

.varDestructive {
    color:            #FFFFFF;
    background-color: var(--g-color-danger);
    border-color:     var(--g-color-danger);
}

.varDestructive:hover {
    border-color:     var(--g-color-red600);
    background-color: var(--g-color-red600);
}

.varLink {
    color:            var(--g-color-secondary);
    background-color: transparent !important;
    padding:          0 !important;
}

.varLink:hover {
    background-color: transparent;
}

.varSubtle {
    color:            var(--g-color-n300);
    background-color: transparent;
    border-color:     transparent;
}

.varSubtle:hover {
    background-color: white;
    border-color:     var(--g-color-n30);
}

.varSubtle + .varSubtle {
    margin-left: 0 !important;
}

.icon {
    display:    inline-block;
    height:     1rem;
    max-height: 1rem;
    font-size:  1rem;
}

.icon + .loadingIcon {
    margin-left: 8px;
}

.loadingIcon {
    max-height:  1rem;
    height:      1rem;
    line-height: 1rem;
    display:     none;
}

.loading,
.disabled {
    opacity: 0.4 !important;
    cursor:  default;
}

.loading .loadingIcon {
    display: inline-block;
}

.fullwidth {
    display:    block;
    text-align: center;
}

.fullwidth + .fullwidth {
    margin-left: 0;
    margin-top:  8px;
}

.spacingCompact {
    padding: 4px 6px;
}

.spacingCompact .children {
    vertical-align: text-bottom;
}

.spacingCompact + .spacingCompact {
    margin-left: 4px;
}

.spacingStandard {
    padding: 8px 16px
}

.spacingLarge {
    padding: 12px 18px;
}