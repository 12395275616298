.alert {
    padding:       calc(2 * var(--g-space));
    border-radius: var(--g-rounding);
    display:       flex;
    flex-wrap:     nowrap;
}

.icon {
    flex-shrink:  0;
    flex-grow:    0;
    margin-right: calc(2 * var(--g-space));
}

.intentInfo {
    background-color: var(--g-color-info-subtle);
    color:            var(--g-color-info-subtle-inverse);
}

.intentNeutral {
    background-color: var(--g-color-neutral-subtle);
    color:            var(--g-color-neutral-subtle-inverse);
}

.intentDanger {
    background-color: var(--g-color-danger-subtle);
    color:            var(--g-color-danger-subtle-inverse);
}

.intentWarning {
    background-color: var(--g-color-warning-subtle);
    color:            var(--g-color-warning-subtle-inverse);
}

.intentSuccess {
    background-color: var(--g-color-success-subtle);
    color:            var(--g-color-success-subtle-inverse);
}