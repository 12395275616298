.item:last-child {
    margin: 0;
}

.spaced {
    display: flex;
    flex-wrap: nowrap;
}

.directionHorizontal {
    flex-direction: row;
}

.directionVertical {
    flex-direction: column;
}