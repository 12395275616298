.avatar {
    border-radius:    500px;
    height:           32px;
    width:            32px;
    display:          flex;
    justify-content:  center;
    align-items:      center;
    background-color: var(--g-color-n20);
}

.image {
    height: 100%;
    width:  100%;
}

.initials {
    font-size: 12px;
}

.icon {
    max-height: 1rem;
    height:     1rem;
    font-size:  1rem;
}