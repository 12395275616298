.badge {
    padding:       3px 10px;
    border-radius: 500px;
    display:       inline-block;
}

.text {
    display:        inline-block;
    font-weight:    600;
    vertical-align: text-bottom;
    font-size:      0.9rem;
}

.dot {
    height:         6px;
    width:          6px;
    border-radius:  50%;
    margin-right:   4px;
    display:        inline-block;
    vertical-align: middle;
}

.intentInfo {
    background-color: var(--g-color-info-subtle);
    color:            var(--g-color-info-subtle-inverse);
}

.intentNeutral {
    background-color: var(--g-color-neutral-subtle);
    color:            var(--g-color-neutral-subtle-inverse);
}

.intentDanger {
    background-color: var(--g-color-danger-subtle);
    color:            var(--g-color-danger-subtle-inverse);
}

.intentWarning {
    background-color: var(--g-color-warning-subtle);
    color:            var(--g-color-warning-subtle-inverse);
}

.intentSuccess {
    background-color: var(--g-color-success-subtle);
    color:            var(--g-color-success-subtle-inverse);
}