.card {
    border-radius: 6px;
    border:        1px solid var(--g-color-border);
    padding:       16px;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    background-color: var(--g-color-n10);
    transition:       background-color 200ms ease;
}