.reference {
    display: inline-block;
}

.menu {
    border-radius:    var(--g-rounding);
    background-color: var(--g-color-n0);
    box-shadow:       2px 2px 8px rgba(0, 0, 0, .06);
    width:            200px;
    box-sizing:       border-box;
    overflow:         hidden;
    z-index:          1;
}

.item {
    padding:       8px 16px;
    cursor:        pointer;
    border-radius: var(--g-rounding);
}

.text {
    font-weight: 500;
}

.item:hover {
    background: var(--g-color-n20);
}

.group {
    padding: 4px;
}

.group + .group {
    border-top:  1px solid var(--g-color-border);
}

.icon {
    margin-right: 8px;
}