.selector {
    display:   flex;
    flex-wrap: nowrap;
    gap:       8px;
}

.dirVertical {
    flex-direction: column;
}

.item {
    flex-grow:     1;
    flex-shrink:   0;
    flex-basis:    0;
    border-radius: var(--g-rounding);
}

.itemActive .itemInner {
    font-weight: 500;
    color:       var(--g-color-secondary);
}

.itemInner {
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
}

.itemChildren {
    flex-grow: 1;
}

.itemIndicator {
    flex-grow:   0;
    flex-shrink: 0;
}
