.reference {
    display:     inline-block;
    line-height: 0;
}

.content {
    background-color: var(--g-color-n700);
    color:            white;
    padding:          4px 6px;
    max-width:        200px;
    border-radius:    var(--g-rounding);
    z-index:          1;
}