.container {
    display:    inline-block;
    transition: border 200ms ease;
    overflow:   hidden;
    position:   relative;
}

.containerClickable {
    cursor: pointer;
}

.containerClickable:hover {
    border-color: var(--g-color-secondary);
}

.containerClickable:hover .placeholder {
    color: var(--g-color-secondary);
}

.placeholder {
    position:   absolute;
    top:        50%;
    left:       50%;
    transform:  translate(-50%, -50%);
    display:    block;
    opacity:    0.7;
    color:      var(--g-color-border);
    font-size:  24px;
    transition: color 200ms ease;
}

.image {
    display:    block;
    height:     100%;
    width:      100%;
    border:     none;
    object-fit: cover;
}