.showmore {

}

.expander {
    display:     inline-flex;
    align-items: center;
    cursor:      pointer;
}

.title {
    color:        var(--g-color-secondary);
    flex-grow:    0;
    flex-shrink:  0;
    margin-right: 4px;
}

.icon {
    color: var(--g-color-secondary);
}

.iconRotated {
    transform: rotate(180deg);
}

.content {
    transition: all 200ms ease;
}