.wizard {
    background: white;
    z-index:    1;
    top:        0;
    right:      0;
    bottom:     0;
    left:       0;
    position:   fixed;
}

.body {
    margin-top: 100px;
    max-width:    550px;
    margin-left:  auto;
    margin-right: auto;
}

.header {
    border-bottom: 1px solid var(--g-color-border);
    padding:       8px 24px;
    display:       flex;
    align-items:   center;
}

.headerClose {
    margin-left:     -8px;
    display:         flex;
    justify-content: center;
    align-items:     center;
    padding:         8px;
    cursor:          pointer;
    transition:      background-color 200ms ease;
    border-radius:   500px;
    font-size:       18px;
    height:          1rem;
    color:           var(--g-color-n300);
}

.headerClose:hover {
    background-color: var(--g-color-n20);
}

.headerTitle {
    margin-left: 4px;
    font-weight: 600;
}

.headerActions {
    margin-left: auto;
}