.textarea {
    width:      100%;
    border:     none;
    background: transparent;
    box-sizing: border-box;
    padding:    8px 12px;
    resize:     vertical;
    display:    block;
    font-size:  1rem;
    color:      inherit;
}