.wrapper {
    z-index:  1;
    top:      0;
    right:    0;
    bottom:   0;
    left:     0;
    position: fixed;
}

.modal {
    background:     white;
    box-shadow:     0 3px 6px 0 rgba(0, 0, 0, 0.12), 0px 7px 14px 0px rgba(60, 66, 87, 0.08);
    max-width:      540px;
    margin:         10vh auto 0;
    border-radius:  var(--g-rounding);
    z-index:        1;
    padding:        28px;
    display:        flex;
    flex-direction: column;
}

.sizeSm {
    max-width: 540px;
}

.sizeMd {
    max-width: 700px;
}

.sizeLg {
    max-width: 1100px;
}

.sizeXl {
    max-width:  90vw;
    max-height: 90vh !important;
    height:     90vh;
    margin-top: 5vh;
}

.header {
}

.headerTop {
    display:         flex;
    justify-content: space-between;
    margin-bottom:   22px;
}

.headerBottom {
    margin-top:    -16px;
    margin-bottom: 26px;
}

.headerTitle {
    font-size:   20px;
    line-height: 1;
    font-weight: 700;
    display:     block;
    align-items: center;
}

.headerRight {
    display:     flex;
    align-items: center;
    gap:         8px;
    margin-top:  -8px;
}

.headerClose {
    display: block;
}

.headerCloseIcon {
    font-size:     22px;
    line-height:   22px;
    cursor:        pointer;
    text-align:    center;
    color:         var(--g-color-n100);
    transition:    opacity 300ms ease;
    border-radius: var(--g-rounding);
    width:         22px;
    height:        22px;
    padding:       4px;
}

.headerCloseIcon:hover {
    background-color: var(--g-color-n20);
    cursor:           pointer;
}

.option {
    display: block;
}

.optionIcon {
    font-size:     22px;
    line-height:   22px;
    cursor:        pointer;
    text-align:    center;
    color:         var(--g-color-n100);
    transition:    opacity 300ms ease;
    border-radius: var(--g-rounding);
    width:         22px;
    height:        22px;
    padding:       4px;
}

.optionIcon:hover {
    background-color: var(--g-color-n20);
    cursor:           pointer;
}

.body {
    overflow-y: auto;
    flex-grow:  1;

    /* Pad body to ensure input hover states aren't cut off */
    padding:    1px;
}

.footer {
    margin-top:                 32px;
    border-bottom-left-radius:  var(--g-rounding);
    border-bottom-right-radius: var(--g-rounding);
}