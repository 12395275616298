.list {
	margin-left: 32px;
}

ul.list {
	list-style: disc;
}

ol.list {
	list-style: decimal;
}

.item {
	padding: 0;
	margin:  0 0 8px 0;
}