.infinite {
	overflow-x: scroll;
	display:    flex;
	flex-wrap:  nowrap;
}

.infinite > div {
	flex-shrink: 0;
	flex-grow:   0;
}

.alignTop {
	align-items: flex-start;
}

.alignCenter {
	align-items: center;
}

.alignBottom {
	align-items: flex-end;
}