.effect {
    transition: background-color 200ms ease, border 200ms ease;
}

.hoverable:hover {
    background-color: var(--g-color-n20);
}

.clickable:hover {
    cursor: pointer;
    background-color: var(--g-color-n20);
}

.selectable:hover {
    border-color: var(--g-color-brand-purple);
}