.spinner {
    width:               24px;
    height:              24px;
    border:              2px solid var(--g-color-n80);
    border-bottom-color: transparent;
    border-radius:       50%;
    display:             inline-block;
    box-sizing:          border-box;
    animation:           rotation 1s linear infinite;
}

.compact {
    width:               18px;
    height:              18px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}