.horizontal {
    border-bottom: 1px solid var(--g-color-border);
    width:         100%;
    min-width:     100%;
}

.vertical {
    align-self:   stretch;
    height:       100%;
    min-height:   100%;
    border-right: 1px solid var(--g-color-border);
}